<template>
	<div id="pdf-box">
		<DefaultLoading v-if="loading || loadingBar" :loading="loading || loadingBar" />

		<v-container v-if="docError">
			<v-row class="fill-height" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert border="top" colored-border type="error" elevation="2">
						{{ $t("document_error") }}
					</v-alert>
				</v-col>
			</v-row>
		</v-container>
		<v-container v-if="error && !this.otpDialog">
			<v-row class="fill-height mt-5" align-content="center" justify="center">
				<v-col cols="12">
					<v-alert text color="#FF000D" border="left">
						<v-icon color="#FF000D">mdi-close-circle-outline</v-icon>
						<strong>{{ errorMsg }}</strong>
					</v-alert>
				</v-col>
			</v-row>
		</v-container>

		<v-container v-if="status === 'SIGNED'">
			<v-alert class="mt-5" border="left" text type="warning">
				{{ $t("document_already_signed") }}
			</v-alert>
		</v-container>

		<v-container v-if="!docError && status !== 'SIGNED' && !loading">
			<div id="pdf-view" class="col-md-12 col-sm-12 pa-0">
				<pdf-viewer @documentError="documentError($event)"></pdf-viewer>
			</div>
		</v-container>

		<div class="footer-message" v-if="!docError && status !== 'SIGNED' && !loading">
			<div>
				<v-row>
					<v-container class="py-0 fill-height">
						<div class="col-md-8 col-xs-12 text-left terms">
							<v-list>
								<v-list-item>
									<template v-slot:default="{ active }">
										<v-list-item-action>
											<v-checkbox @change="changeConfirm()" dark color="#F36E21"
												v-model="terms_accepted" :input-value="active"></v-checkbox>
										</v-list-item-action>

										<v-list-item-content class="white--text">
											{{ $t("read_understand_doc") }}
										</v-list-item-content>
									</template>
								</v-list-item>
							</v-list>
						</div>
						<div class="col-md-2 col-6 sign">
							<v-btn large color="#47D764" :disabled="!confirm" block dark class="sign-reject-btn"
								:loading="loading" @click="openLocationDialog()">
								<v-icon class="mr-2">mdi-pencil-box-outline</v-icon>
								{{ $t("sign") }}
							</v-btn>
						</div>
						<div class="col-md-2 col-6 reject" v-if="!isNoReject">
							<v-btn large color="#FF000D" class="sign-reject-btn" block dark @click="reject()">
								<v-icon class="mr-2">mdi-close-circle-outline</v-icon>
								{{ $t("reject") }}
							</v-btn>
						</div>
					</v-container>
				</v-row>
			</div>
		</div>

		<sign-confirm-otp-modal :dialog="otpDialog" :errorMsg="errorMsg" :error="error" @submitOtpCode="submitOtpCode"
			@resendOtpCode="sendOtpCode" />

		<sign-confirm-pad-modal :dialog="padDialog" :errorMsg="errorMsg" :error="error" @submitSignPad="submitSignPad" />
	</div>
</template>

<script>
import PdfViewer from "../components/shared/PdfViewer.component";
import SignConfirmOtpModal from "../components/shared/SignConfirmOtpModal.component";
import SignConfirmPadModal from "../components/shared/SignConfirmPadModal.component";
import DefaultLoading from "../components/shared/DefaultLoading.component";
import { SignaturesService } from "../services/signatures-service";
import moment from "moment";

export default {
	components: {
		PdfViewer,
		SignConfirmOtpModal,
		SignConfirmPadModal,
		DefaultLoading,
	},

	data: function () {
		return {
			terms_accepted: null,
			confirm: false,
			loading: true,
			loadingBar: false,
			docError: false,
			error: false,
			requestId: localStorage.getItem("request_id"),
			token: localStorage.getItem("access_token"),
			authCitizenId: localStorage.getItem("auth_citizen_id"),
			isAuthCitizen: localStorage.getItem("is_auth_citizen"),
			showPdf: false,
			otpStatus: false,
			otpDialog: false,
			otpSend: false,
			otpCode: "",
			padDialog: false,
			padStatus: false,
			padImage: "",
			userLatitude: "",
			userLongitude: "",
			errorMsg: "",
			submitLocation: false,
		};
	},

	watch: {
		otpSend() {
			if (this.otpStatus) {
				this.otpDialog = true;
				this.padDialog = false;
			}
		},
		submitLocation() {
			this.signDocument();
		},
	},

	computed: {
		auth() {
			return this.$store.state.common.auth;
		},
		status() {
			return this.$store.state.common.status;
		},
		authId() {
			return this.$store.state.common.authId;
		},
		hasAssociation() {
			return this.$store.getters['common/hasAssociation']
		},
		isNoReject() {
        return localStorage.getItem('noreject') === 'true'}
	},
	mounted() {
		this.loadRequestData();
	},
	methods: {
		changeConfirm() {
			this.confirm = !this.confirm;
		},
		loadRequestData() {
			this.loading = true;
			this.$store
				.dispatch("common/getRequestData", {
					token: this.token,
					requestId: this.requestId,
					authId: this.authId,
				})
				.then((response) => {
					this.loading = false;
					const signRequest = response.data;

					this.padStatus = signRequest.pad;
					this.otpStatus = signRequest.otp;

					if (signRequest.id == this.$route.params.id) {
						this.document = signRequest.signatureTransaction.description;
						this.signerName = signRequest.signerName;
						this.ownerName = signRequest.signatureTransaction.ownerName;
						this.title = signRequest.signatureTransaction.document.title;
						this.comments = signRequest.signatureTransaction.comments;
						this.date = signRequest.signatureTransaction.dueDate;
					}
				})
				.catch(() => {
					this.loading = false;
				});
		},
		signDocument() {
			this.error = false;
			if (this.padStatus) {
				this.padDialog = true;
			} else if (this.otpStatus) {
				this.sendOtpCode();
			} else {
				this.submitDocument();
			}
		},
		getSubmitData() {
			let submitData = {
				comments: "",
				location:
					this.userLatitude && this.userLongitude
						? this.userLatitude + ", " + this.userLongitude
						: "",
				lastRefresh: moment(String(new Date())).format("YYYY-MM-DDTHH:mm:ssZZ"),
			};

			const requestType = localStorage.getItem("type");
			if (requestType == "ONETIMEWITHID") {
				submitData["authId"] = this.authId;
			}

			if (this.otpStatus) {
				submitData["otp"] = this.otpCode;
			}

			if (this.padStatus) {
				submitData["signatureImage"] = this.padImage;
			}

			return submitData;
		},
		submitDocument() {
			this.error = false;
			if (this.requestId) {
				this.loadingBar = true;
				SignaturesService.signDocument(this.requestId, this.getSubmitData())
					.then((response) => {
						this.loadingBar = false;
						this.status = response.data.status;
						if (response.data.status === "SIGNED") {
							this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}summary` });
						} else {
							this.showPdf = true;
						}
					})
					.catch((error) => {
						this.loadingBar = false;
						if (error.response.status == 422) {
							this.error = true;
							this.errorMsg =
								error.response.data.description == "Invalid otp code"
									? this.$t("invalid_otp")
									: error.response.data.description;
						}
						if (error.response.status == 403) {
							this.error = true;
							this.errorMsg = error.response.data.description;
						}
						if (error.response.status == 500) {
							this.error = true;
							this.errorMsg = this.$t("server_error");
						}
						this.showPdf = false;
						document.body.scrollTop = 0;
						document.documentElement.scrollTop = 0;
					});
			} else {
				this.error = true;
			}
		},
		sendOtpCode() {
			this.error = false;
			if (this.requestId) {
				this.loadingBar = true;
				this.otpDialog = true;

				let data = {
					comments: "",
					authId: this.authId,
					location: "",
					lastRefresh: "",
					otp: "",
					signatureImage: "",
				};
				SignaturesService.sendOtp(this.requestId, data, this.token)
					.then(() => {
						this.loadingBar = false;
					})
					.catch((error) => {
						this.loadingBar = false;
						if (error.response.status == 422) {
							this.otpSend = true;
						}
					});
			} else {
				this.error = true;
			}
		},
		reject() {
			this.$router.push({ name: `${this.hasAssociation ? 'custom-' : ''}rejected` });
		},
		documentError(value) {
			this.docError = value;
		},
		submitOtpCode(otpCode) {
			this.otpCode = otpCode;
			if (this.otpStatus) {
				this.otpDialog = true;
				this.submitDocument();
			}
		},
		submitSignPad(padImage) {
			this.padImage = padImage;
			if (this.padImage) {
				if (this.otpStatus) {
					this.sendOtpCode();
				} else {
					this.loadingBar = true;
					setTimeout(
						function () {
							this.submitDocument();
						}.bind(this),
						200
					);
				}
			}
		},
		openLocationDialog() {
			if (navigator && navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position) => {
						this.userLatitude = position.coords.latitude;
						this.userLongitude = position.coords.longitude;
						this.submitLocation = true;
					},
					() => {
						this.userLatitude = "";
						this.userLongitude = "";
						this.submitLocation = true;
					}
				);
			}
		},
	},
};
</script>
