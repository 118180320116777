<template>
  <v-container style="height: 400px" v-if="loading" class="model-loading">
    <v-row class="fill-height" align-content="center" justify="center">
      <v-col class="subtitle-1 text-center" cols="12">

        {{ $t("please_wait") }} ....
                 <!-- Show the notice only when loading is true -->
                 <v-alert v-if="loading" class="mt-2 mb-4" type="error" border="left" color="red darken-3" text>
                  <strong> {{ $t("multi_url.warning_message") }}</strong>
                </v-alert>      
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          color="deep-purple accent-4"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
};
</script>
