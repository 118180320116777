<template>
  <v-flex>
    <DefaultLoading v-if="loading" :loading="loading" />

    <div v-if="!loading && !error" class="pdf-wrapper">
      <vue-pdf-app
        :pdf="pdfContent"
        :file-name="fileName"
        page-scale="page-width"
        v-pdf-app-load
      ></vue-pdf-app>
    </div>
  </v-flex>
</template>

<script>
import { SignaturesService } from "../../services/signatures-service";
import DefaultLoading from "./DefaultLoading.component";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

export default {
  name: "PdfViewer",
  components: { VuePdfApp, DefaultLoading },
  data: () => ({
    currentPage: 0,
    pageCount: 0,
    loading: false,
    error: false,
    transactionId: localStorage.getItem("transaction_id"),
    requestId: localStorage.getItem("request_id"),
    authId: localStorage.getItem("auth_id"),
    pdfContent: null,
    fileName: localStorage.getItem("file_name"),
  }),

  mounted() {
    this.loadDocument();
  },

  methods: {
    loadDocument() {
      this.error = false;
      if (this.transactionId) {
        this.loading = true;
        SignaturesService.getSignatureRequestDocument(
          this.requestId,
          this.authId
        )
          .then((response) => {
            this.$emit("documentError", false);
            this.pdfContent = response.data;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
            if (error.response.status == 404) {
              this.error = true;
              this.$emit("documentError", true);
            }
          });
      } else {
        this.error = true;
        this.$emit("documentError", true);
      }
    },
  },
};
</script>
